const stagImg = 'https://img.stag.vn/cdn-cgi/image';
const stagImgix = 'https://stag-media.imgix.net';
const gcsHost = 'https://stag_media.storage.googleapis.com';

export function dataURItoBlob(dataurl, filename) {
  if (!dataurl) return null;

  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function convertImageFormData(file, fieldName = 'photo') {
  const formData = new FormData();
  formData.append(fieldName, file);

  return formData;
}

export const stagMediaIMGX = (url, width, height) => {
  if (!url) return null;

  if (!width & !height) {
    width = 474;
    height = 256;
  }

  let arr = [];
  if (height) arr.push(`height=${height}`);
  if (width) arr.push(`width=${width}`);
  const params = arr.join('&');

  if (url.startsWith('https://stag-media.imgix.net')) {
    return `${url}?${params}`;
  }

  let fixedUrl = url;
  if (url.startsWith('https://stag_media.storage.googleapis.com')) {
    fixedUrl = url.replace(
      'https://stag_media.storage.googleapis.com',
      'https://stag-media.imgix.net'
    );
  }

  return `${fixedUrl}?${params}`;
};

export const cloudflareCdn = (url, width, height) => {
  if (!url) return null;

  if (!width & !height) {
    width = 474;
    height = 256;
  }

  let arr = [];
  if (height) arr.push(`height=${height}`);
  if (width) arr.push(`width=${width}`);
  arr.push('quality=75');
  arr.push('format=auto');

  const params = arr.join(',');

  if (url.startsWith('/')) {
    return `${stagImg}/${params}${url}`;
  } else if (!url.startsWith('http')) {
    return `${stagImg}/${params}/${url}`;
  }

  let fixedUrl = url;
  if (url.startsWith(gcsHost)) {
    fixedUrl = url.replace(gcsHost, `${stagImg}/${params}`);
  } else if (url.startsWith(stagImgix)) {
    fixedUrl = url.replace(stagImgix, `${stagImg}/${params}`);
  }

  return fixedUrl;
};
