export const appsConfig = {
  market: {
    key: 'market',
    label: 'Market Data',
    color: '#f56a00',
    namespace: 'marketdb',
    description: 'All market data, analytics, news...',
  },
  edu: {
    key: 'edu',
    label: 'Education',
    color: '#7265e6',
    namespace: 'stagedu',
    description: 'Manage users, content, and data for Stock Edu app',
  },
  investment: {
    key: 'investment',
    label: 'Investment',
    color: '#ffbf00',
    namespace: 'investcore',
    description: 'Manage investors, orders on Stag investment app',
  },
  // investcore: {
  //   key: 'investcore',
  //   label: 'Investcore',
  //   color: 'red',
  //   namespace: 'investcore',
  //   description: 'Stag platform system ops',
  // },
  // content: {
  //   key: 'cms',
  //   label: 'Admin Config',
  //   color: '#00a2ae',
  //   namespace: 'stagapps',
  //   description:
  //     'Misc, Ops, website config such as menu, settings, and other stuff...',
  // },
};
