import { Button, Tag } from 'antd';
import apiCall from 'apps/investment/apiCalls/product';
import { DetailPage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { Link } from 'react-router-dom';
import {
  investmentStatusColors,
  investmentStatusLabels,
} from 'utils/constants';
import { formatISODate } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';
import ItemForm from './Form';
import ItemDetail from './detail';

const columnsGenerator = params => [
  {
    title: 'Code',
    render: o => `${o.code} ${o.alias ? `(${o.alias})` : ''}`,
  },
  {
    title: 'Logo',
    dataIndex: 'logo',
    render: value =>
      value && <img width={100} src={cloudflareCdn(value, 100)} />,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: 'Fund Type',
    render: o => `${o.fund_type_name} (${o.fund_type})`,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: status => {
      return (
        <Tag color={investmentStatusColors[status]}>
          {investmentStatusLabels[status]}
        </Tag>
      );
    },
  },
  {
    title: 'Fund Manager',
    dataIndex: 'fund_manager',
  },
  {
    title: 'Custodian Banks',
    dataIndex: 'custodian_bank',
    render: value =>
      value && value.name ? `${value.name} - ${value.code}` : '-',
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    sorter: true,
    render: value => formatISODate(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/investment/products/${record.id}`}>View</Link>
        </Button>
      </Button.Group>
    ),
  },
];

export const InvestmentProductListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    title="All Fund Products"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="data"
    {...props}
  />
);

export const InvestmentProductEditPage = props => (
  <EditPage
    title="Product"
    apiCall={apiCall}
    listUrl={`/investment/products`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
);

export const InvestmentProductDetailPage = props => (
  <DetailPage
    title="Fund Product Detail"
    apiCall={apiCall}
    listUrl={`/investment/products`}
    ItemDetail={ItemDetail}
    extraActions={id => (
      <>
        <Button type="primary">
          <Link to={`/investment/products/${id}/edit`}>Edit</Link>
        </Button>
      </>
    )}
    resourceName="data"
    {...props}
  />
);
