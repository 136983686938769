import { Descriptions, Tag } from 'antd';
import { securityAccountStatusColors } from 'apps/investment/constants/user';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/formatDate';

const { Item: DescItem } = Descriptions;

const ItemDetail = ({ item }) => {
  return (
    <div className="flex flex-col gap-6">
      <Descriptions title={'General info'} column={1} bordered>
        <DescItem label="User">
          {
            <Link to={`/investment/users/${item.user_id}`}>
              {item.name ? `${item.name} (${item.email})` : item.email}
            </Link>
          }
        </DescItem>
        <DescItem label="Status">
          <Tag color={securityAccountStatusColors[item.status]}>
            {item.status_label}
          </Tag>
        </DescItem>
        <DescItem label="Trading Number">{item.account_number}</DescItem>
        <DescItem label="Source">{item.source}</DescItem>
        <DescItem label="Contract Number">{item.contract_number}</DescItem>
        <DescItem label="Contract Status">
          {item.is_contract_approve ? (
            <Tag color={'green'}>Đã ký</Tag>
          ) : (
            <Tag color={'yellow'}>Chưa ký</Tag>
          )}
        </DescItem>
        <DescItem label="Security Manager">
          {item.security_manager?.toUpperCase()}
        </DescItem>

        <DescItem label="Authentication Method">
          {item.authentication_method?.toUpperCase()}
        </DescItem>
        <DescItem label="Enable Margin Trading Account">
          {item.enable_margin_trading_account ? 'Yes' : 'No'}
        </DescItem>
        <DescItem label="Enable Payment In Advance">
          {item.enable_payment_in_advance ? 'Yes' : 'No'}
        </DescItem>
        <Descriptions.Item label="Created At">
          {formatDateTime(item.created_at)}
        </Descriptions.Item>
        <Descriptions.Item label="Updated At">
          {formatDateTime(item.updated_at)}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title={'Identification'} column={1} bordered>
        <DescItem label="Full Name">{item.full_name}</DescItem>
        <DescItem label="Email">{item.email}</DescItem>
        <DescItem label="Phone">{item.phone}</DescItem>
        <DescItem label="Address">{item.address}</DescItem>
        <DescItem label="Date of Birth">{item.date_of_birth}</DescItem>
        <DescItem label="Gender">
          {item.gender === 'male' ? 'Male' : 'Female'}
        </DescItem>
        <DescItem label="Nationality">{item.nationality}</DescItem>
        <DescItem label="ID Number">{item.id_number}</DescItem>
        <DescItem label="ID Issue Date">{item.id_issue_date}</DescItem>
        <DescItem label="ID Issue Place">{item.id_issue_place}</DescItem>
        <DescItem label="Tax Number">{item.tax_number}</DescItem>
      </Descriptions>
      <Descriptions title={'Bank info'} column={1} bordered>
        <DescItem label="Bank Name">{item.bank_name}</DescItem>
        <DescItem label="Bank Account Name">{item.bank_account_name}</DescItem>
        <DescItem label="Bank Account Number">
          {item.bank_account_number}
        </DescItem>
        <DescItem label="Bank Branch Name">{item.bank_branch_name}</DescItem>
      </Descriptions>
    </div>
  );
};

export default ItemDetail;
