import apiCall from 'apps/investment/apiCalls/integration';
import { DetailPage, ListPage } from 'common/ui/AdminCRUD/page';
import { Link } from 'react-router-dom';
import ItemDetail from './detail';

const columnsGenerator = params => [
  {
    title: 'ID',
    dataIndex: 'ID',
    render: value => (
      <Link to={`/investment/integration-logs/${value}`}>
        {value || 'detail'}
      </Link>
    ),
  },
  {
    title: 'Email',
    dataIndex: 'Request',
    render: value => value.body.email,
  },
  {
    title: 'Path',
    dataIndex: 'Request',
    render: value => value.path,
  },
  {
    title: 'Method',
    dataIndex: 'Request',
    render: value => value.method,
  },
  {
    title: 'Host',
    dataIndex: 'Request',
    render: value => value.host,
  },
  {
    title: 'Status Code',
    dataIndex: 'Response',
    render: value => value.status,
  },
  {
    title: 'Error Code',
    dataIndex: 'Response',
    render: value => value.body.error_code,
  },

  {
    title: 'Error Code',
    dataIndex: 'Response',
    render: value => (
      <div dangerouslySetInnerHTML={{ __html: value.body.message }} />
    ),
  },
];

export const IntegrationLogListPage = (props, { params }) => (
  <ListPage
    title="Integration Logs"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="data"
    {...props}
  />
);

export const IntegrationLoglDetailPage = (props, { params }) => (
  <DetailPage
    title="Integration Log Detail"
    listUrl={`/investment/integration-logs`}
    apiCall={apiCall}
    ItemDetail={ItemDetail}
    params={params}
    resourceName="data"
    {...props}
  />
);
