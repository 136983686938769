import { Descriptions, Tag } from 'antd';
import {
  fundAccountESignStatusColors,
  fundAccountESignStatusLabels,
  fundAccountStatusColors,
} from 'apps/investment/constants/fundAccount';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/formatDate';

const { Item: DescItem } = Descriptions;

const ItemDetail = ({ item }) => {
  return (
    <div className="flex flex-col gap-6">
      <Descriptions title={'General info'} column={1} bordered>
        <DescItem label="User">
          {
            <Link to={`/investment/users/${item.user_id}`}>
              {item.name ? `${item.name} (${item.email})` : item.email}
            </Link>
          }
        </DescItem>
        <DescItem label="Status">
          <Tag color={fundAccountStatusColors[item.status]}>{item.status}</Tag>
        </DescItem>
        <DescItem label="Account Number">{item.trading_code}</DescItem>
        <DescItem label="Fund Customer ID">{item.fund_account_id}</DescItem>
        <DescItem label="eSign">
          {item.sign_contract_status ? (
            <Tag
              color={fundAccountESignStatusColors[item.sign_contract_status]}
            >
              {fundAccountESignStatusLabels[item.sign_contract_status]}
            </Tag>
          ) : (
            <Tag color={'yellow'}>{item.sign_contract_status}</Tag>
          )}
        </DescItem>
        <DescItem label="VSD">
          {item.is_vsd_approved ? (
            <Tag color={'green'}>Duyệt</Tag>
          ) : (
            <Tag color={'yellow'}>Chưa duyệt</Tag>
          )}
        </DescItem>
        <DescItem label="Enable B2B">
          {item.enabled_b2b ? (
            <Tag color="green">Đã kích hoạt</Tag>
          ) : (
            <Tag color="yellow">Chưa kích hoạt</Tag>
          )}
        </DescItem>
        <DescItem label="eKYC Status (from Fund Manager)">
          <Tag color="gray">{item.kyc_status}</Tag>
        </DescItem>
        <Descriptions.Item label="Approved At">
          {formatDateTime(item.approved_at)}
        </Descriptions.Item>
        <Descriptions.Item label="Created At">
          {formatDateTime(item.created_at)}
        </Descriptions.Item>
        <Descriptions.Item label="Updated At">
          {formatDateTime(item.updated_at)}
        </Descriptions.Item>
        <Descriptions.Item label="Notes">{item.note}</Descriptions.Item>
      </Descriptions>
      <Descriptions title={'Identification'} column={1} bordered>
        <DescItem label="Full Name">{item.full_name}</DescItem>
        <DescItem label="Email">{item.email}</DescItem>
        <DescItem label="Phone">{item.phone}</DescItem>
        <DescItem label="Address">{item.address}</DescItem>
        <DescItem label="Date of Birth">{item.date_of_birth}</DescItem>
        <DescItem label="Gender">
          {item.gender === 'male' ? 'Male' : 'Female'}
        </DescItem>
        <DescItem label="Nationality">{item.nationality}</DescItem>
        <DescItem label="ID Number">{item.id_number}</DescItem>
        <DescItem label="ID Issue Date">{item.id_issue_date}</DescItem>
        <DescItem label="ID Issue Place">{item.id_issue_place}</DescItem>
      </Descriptions>
      <Descriptions title={'Bank info'} column={1} bordered>
        <DescItem label="Bank Name">{item.bank_name}</DescItem>
        <DescItem label="Bank Account Name">{item.bank_account_name}</DescItem>
        <DescItem label="Bank Account Number">
          {item.bank_account_number}
        </DescItem>
        <DescItem label="Bank Branch Name">{item.bank_branch_name}</DescItem>
      </Descriptions>
    </div>
  );
};

export default ItemDetail;
