import { Button, Tag } from 'antd';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useFetchApiList } from '@stagapps/redux-utils';
import chapterListApiCall from 'apps/education/apiCalls/chapter/list';
import MetaDecorator from 'apps/home/components/MetaDecorator';
import PageTitle from 'apps/home/components/PageTitle';
import DataTable from 'common/ui/DataTable';
import {
  accessColors,
  accessLabel,
  statusColors,
  statusLabel,
} from 'utils/constants';
import { formatISODate } from 'utils/formatDate';
import { cloudflareCdn } from 'utils/image';

const columnsGenerator = params => [
  {
    title: 'Chapter ID',
    dataIndex: 'id',
  },
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Photo',
    dataIndex: 'photo',
    render: value =>
      value && <img width={100} src={cloudflareCdn(value, 100)} />,
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    render: value => formatISODate(value),
  },
  {
    title: 'Modified',
    dataIndex: 'modified_at',
    render: value => formatISODate(value),
  },
  {
    title: 'Ordering',
    dataIndex: 'ranking',
  },
  {
    title: 'Access Level',
    dataIndex: 'access',
    render: value => {
      return <Tag color={accessColors[value]}>{accessLabel[value]}</Tag>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => {
      return <Tag color={statusColors[value]}>{statusLabel[value]}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => {
      const params = useParams();

      return (
        <Button.Group>
          <Button shape="round">
            <Link
              to={`/edu/courses/${params.course_id}/chapters/${record.id}/edit`}
            >
              Edit
            </Link>
          </Button>
          <Button shape="round">
            <Link
              to={`/edu/courses/${params.course_id}/chapters/${record.id}/lessons`}
            >
              All Lessons
            </Link>
          </Button>
        </Button.Group>
      );
    },
  },
];

const Chapters = () => {
  const params = useParams();

  const {
    data: items,
    load: fetchItems,
    isLoading,
    paging,
  } = useFetchApiList(chapterListApiCall, { resourceName: 'items' });

  useEffect(() => {
    fetchItems({ course: params.course_id });
  }, []);

  var title = 'All Chapters';

  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle
        title={title}
        extra={[
          <Button key="1" type="primary">
            <Link to={`/edu/courses/${params.course_id}/chapters/create`}>
              Create
            </Link>
          </Button>,
        ]}
      />

      <DataTable
        rowKey="id"
        columns={columnsGenerator(params)}
        dataSource={items}
        totalItems={paging.count}
        currentPage={paging.page}
        pageSize={paging.page_size}
        dataFetcher={fetchItems}
        loading={isLoading}
      />
    </>
  );
};

export default Chapters;
