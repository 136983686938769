import { EditOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import {
  orderTypeColors,
  orderTypeLabels,
} from 'apps/investment/constants/fundOrder';
import {
  userProgramOrderMethodColors,
  userProgramOrderStatus,
  userProgramOrderStatusColors,
} from 'apps/investment/constants/programOrder';
import UIIconTooltip from 'common/ui/UIIconTooltip';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/formatDate';

export const userProgramOrdersColumnsGenerator = params => [
  {
    dataIndex: 'id',
    align: 'center',
    render: id => (
      <Link to={`/investment/user-program-orders/${id}`}>View</Link>
    ),
  },
  {
    title: 'User',
    dataIndex: 'user',
    render: value => (value && value.email ? value.email : '-'),
  },
  {
    title: 'Method',
    dataIndex: 'interval',
    align: 'center',
    render: (text, record) => {
      let createSource = record.create_source;
      return (
        <Tag color={userProgramOrderMethodColors[createSource]}>
          {record.create_source_label}
        </Tag>
      );
    },
  },
  {
    title: 'Type',
    dataIndex: 'order_type',
    align: 'center',
    render: (text, record) => {
      let orderType = record.order_type;
      return (
        <Tag color={orderTypeColors[orderType]}>
          {orderTypeLabels[orderType]}
        </Tag>
      );
    },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    sorter: true,
    type: 'formatNumber',
  },
  {
    title: 'Matched Price',
    dataIndex: 'matched_price',
    type: 'formatNumber',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    type: 'formatNumber',
  },
  {
    title: 'Product',
    dataIndex: 'product_code',
    align: 'center',
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    sorter: true,
    render: value => formatDateTime(value),
  },
  {
    title: 'Status',
    align: 'center',
    render: o => {
      return (
        <Tag color={userProgramOrderStatusColors[o.status]}>
          {o.status_label}
        </Tag>
      );
    },
  },
  {
    title: 'Fund Order',
    dataIndex: 'order',
    align: 'center',
    render: order => {
      if (!order) {
        return '-';
      }

      return <Link to={`/investment/orders/${order.id}`}>View order</Link>;
    },
  },
  {
    title: 'User Program',
    dataIndex: 'user_program',
    align: 'center',
    render: program => {
      if (!program) {
        return '-';
      }

      return (
        <Link to={`/investment/user-programs/${program.id}`}>View program</Link>
      );
    },
  },
  {
    title: 'Action',
    key: 'action',
    align: 'center',
    render: (text, record) => (
      <Link to={`/investment/user-program-orders/${record.id}/edit`}>
        <UIIconTooltip title="Edit" Icon={EditOutlined} />
      </Link>
    ),
  },
];

export const rebundleColumns = [
  {
    title: 'ID',
    dataIndex: 'code',
    render: (value, record) => (
      <Link to={`/investment/user-program-orders/${record.id}`}>{value}</Link>
    ),
  },
  {
    title: 'User',
    dataIndex: 'user',
    render: value => (value && value.email ? value.email : '-'),
  },
  {
    title: 'Interval',
    dataIndex: 'interval',
    align: 'right',
  },
  {
    title: 'Order Type',
    dataIndex: 'order_type',
    align: 'center',
    render: (text, record) => {
      let orderType = record.order_type;

      return (
        <Tag color={orderTypeColors[orderType]}>
          {orderTypeLabels[orderType]}
        </Tag>
      );
    },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    type: 'formatNumber',
  },
  {
    title: 'Product Code',
    dataIndex: 'product_code',
    align: 'center',
  },

  {
    title: 'Created',
    dataIndex: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: status => {
      return (
        <Tag color={userProgramOrderStatusColors[status]}>
          {userProgramOrderStatus[status]}
        </Tag>
      );
    },
  },
  {
    title: 'Fund Order',
    dataIndex: 'order',
    render: order => {
      if (!order) {
        return '-';
      }

      return <Link to={`/investment/orders/${order.id}`}>{order.code}</Link>;
    },
  },
  {
    title: 'User Program',
    dataIndex: 'user_program',
    render: program => {
      if (!program) {
        return '-';
      }

      return (
        <Link to={`/investment/user-programs/${program.id}`}>
          {program.code}
        </Link>
      );
    },
  },
];
