import { useEffect, useState } from 'react';
import slugify from 'slugify';

import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Image,
  Input,
  InputNumber,
  Select,
  Space,
  Upload,
} from 'antd';
import imageUploadCall from 'apps/education/apiCalls/editorImage/upload';
import TextEditor from 'common/form/TextEditor';
import { DRAFT_STATUS, PUBLISH_STATUS } from 'utils/constants';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const { Option } = Select;

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  const { photo, photo_secondary, logo, status } = initialValues;

  const onBlur = () => {
    const inputValue = form.getFieldsValue();
    !inputValue.slug &&
      form.setFieldsValue({ slug: slugify(inputValue.title).toLowerCase() });
  };

  const [isRemoved, setIsRemoved] = useState(false);
  const [isRemovedPhotoSecondary, setIsRemovedPhotoSecondary] = useState(false);
  const [isRemovedLogo, setIsRemovedLogo] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const removePhoto = () => {
    setIsRemoved(true);
  };

  const removePhotoSecondary = () => {
    setIsRemovedPhotoSecondary(true);
  };

  const removeLogo = () => {
    setIsRemovedLogo(true);
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  var draftButtonLabel = 'Save Draft';
  var publishButtonLabel = 'Publish';

  if (status == PUBLISH_STATUS) {
    publishButtonLabel = 'Update';
    draftButtonLabel = 'Switch to Draft';
  }

  const isGameRoomPublished = status == PUBLISH_STATUS;
  const photoFields = ['photo', 'photo_secondary', 'logo'];

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={values =>
        onFinish(
          { ...values, status: isDraft ? DRAFT_STATUS : PUBLISH_STATUS },
          photoFields
        )
      }
      initialValues={initialValues}
      scrollToFirstError
      onBlur={onBlur}
    >
      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="VOZ Mùa 4" />
      </Form.Item>

      <Form.Item name="excerpt" label="Excerpt">
        <Input.TextArea rows={10} />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <TextEditor uploadCallback={imageUploadCall} value={form.fieldValue} />
      </Form.Item>

      <Form.Item
        name="photo"
        label="Upload a photo"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      {isRemoved || !photo ? null : (
        <Form.Item
          label="Existing photo"
          style={{
            visibility,
          }}
        >
          <Button
            type="primary"
            shape="circle"
            size="small"
            style={{
              backgroundColor: 'red',
              position: 'absolute',
              zIndex: 99,
              left: 5,
              top: 5,
            }}
            onClick={() => removePhoto()}
          >
            X
          </Button>
          <Image width={200} src={photo}></Image>
        </Form.Item>
      )}

      <Form.Item
        name="photo_secondary"
        label="Upload a photo secondary"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      {isRemovedPhotoSecondary || !photo_secondary ? null : (
        <Form.Item
          label="Existing photo"
          style={{
            visibility,
          }}
        >
          <Button
            type="primary"
            shape="circle"
            size="small"
            style={{
              backgroundColor: 'red',
              position: 'absolute',
              zIndex: 99,
              left: 5,
              top: 5,
            }}
            onClick={() => removePhotoSecondary()}
          >
            X
          </Button>
          <Image width={200} src={photo_secondary}></Image>
        </Form.Item>
      )}

      <Form.Item
        name="logo"
        label="Upload a logo"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      {isRemovedLogo || !logo ? null : (
        <Form.Item
          label="Existing photo"
          style={{
            visibility,
          }}
        >
          <Button
            type="primary"
            shape="circle"
            size="small"
            style={{
              backgroundColor: 'red',
              position: 'absolute',
              zIndex: 99,
              left: 5,
              top: 5,
            }}
            onClick={() => removeLogo()}
          >
            X
          </Button>
          <Image width={200} src={logo}></Image>
        </Form.Item>
      )}

      <Form.Item name="user_count" label="User Join">
        <InputNumber disabled={true} value={initialValues.user_count} />
      </Form.Item>

      <Form.Item
        name="ordering"
        label="Ordering"
        tooltip="Smallest number will be on top: 1,2,3.."
      >
        <InputNumber />
      </Form.Item>

      <Form.Item name="theme_color" label="Theme Color">
        <Input placeholder="#ffffff" />
      </Form.Item>

      <Form.Item name="theme_color_secondary" label="Theme Color Secondary">
        <Input placeholder="#ffffff" />
      </Form.Item>

      <Form.Item name="access" label="Access Level">
        <Select>
          <Option key={1001} value={1001}>
            Public
          </Option>
          <Option key={1002} value={1002}>
            Private - Direct access but not listed
          </Option>
          <Option key={1003} value={1003}>
            Listed Only - Listing only but not direct access
          </Option>
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Space>
          <Button htmlType="submit" type="primary" loading={submiting}>
            {publishButtonLabel}
          </Button>
          {!isGameRoomPublished && (
            <Button danger onClick={() => setIsDraft(true)} loading={submiting}>
              {draftButtonLabel}
            </Button>
          )}
          {onDelete ? (
            <Button onClick={onDelete} danger>
              Move to Trash
            </Button>
          ) : null}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
