import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Image,
  Input,
  InputNumber,
  Select,
  Space,
  Upload,
} from 'antd';
import imageUploadCall from 'apps/education/apiCalls/editorImage/upload';
import { mappingLessonType } from 'apps/education/apiCalls/lesson/option';
import TextEditor from 'common/form/TextEditor';
import { useEffect, useState } from 'react';
import slugify from 'slugify';
import {
  Container,
  Description,
  Screen,
  StyledImage,
  Title,
} from 'styles/styledComponent/component.js';
import { DRAFT_STATUS, PUBLISH_STATUS } from 'utils/constants';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  const { photo, status } = initialValues;
  const onBlur = () => {
    const inputValue = form.getFieldsValue();
    !inputValue.slug &&
      form.setFieldsValue({ slug: slugify(inputValue.title).toLowerCase() });
  };
  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const [isRemoved, setIsRemoved] = useState(false);
  const [isDraft, setIsDraft] = useState(false);

  const removePhoto = () => {
    setIsRemoved(true);
  };
  const [preview, setPreview] = useState();
  const { title, photo: photoPreview = [], slug, description } =
    preview || initialValues;
  const base64Photo = photoPreview[0]?.thumbUrl;

  var draftButtonLabel = 'Save Draft';
  var publishButtonLabel = 'Publish';

  if (status == PUBLISH_STATUS) {
    publishButtonLabel = 'Save';
    draftButtonLabel = 'Switch to Draft';
  }

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Form
        style={{ width: '75%' }}
        {...formItemLayout}
        form={form}
        name={name || 'item_form'}
        onFinish={values =>
          onFinish({
            ...values,
            status: isDraft ? DRAFT_STATUS : PUBLISH_STATUS,
            isRemoved,
          })
        }
        initialValues={initialValues}
        scrollToFirstError
        onBlur={onBlur}
        onValuesChange={(changedValues, allValues) => setPreview(allValues)}
        onLoad={() => setPreview(form.getFieldsValue())}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: 'Please input the required field',
              whitespace: true,
            },
          ]}
        >
          <Input placeholder="Mã chứng khoán là gì?" />
        </Form.Item>

        <Form.Item
          name="slug"
          label="URL Slug"
          tooltip="URL Slug"
          rules={[
            {
              required: true,
              message: 'Please input the required field',
              whitespace: true,
            },
          ]}
        >
          <Input value={form.fieldValue} placeholder="ma-chung-khoan-la-gi" />
        </Form.Item>

        <Form.Item name="description" label="Description">
          <TextEditor
            uploadCallback={imageUploadCall}
            value={form.fieldValue}
          />
        </Form.Item>

        <Form.Item name="excerpt" label="Excerpt">
          <Input.TextArea rows={10} />
        </Form.Item>

        <Form.Item
          name="photo"
          label="Upload new photo"
          valuePropName="fileList "
          getValueFromEvent={normFile}
        >
          <Upload.Dragger listType="picture" beforeUpload={() => false}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload.Dragger>
        </Form.Item>
        {isRemoved || !photo ? null : (
          <Form.Item
            label="Upload a new photo"
            style={{
              visibility,
            }}
          >
            <Button
              type="primary"
              shape="circle"
              size="small"
              style={{
                backgroundColor: 'red',
                position: 'absolute',
                zIndex: 99,
                left: 5,
                top: 5,
              }}
              onClick={() => removePhoto()}
            >
              X
            </Button>
            <Image width={200} src={photo}></Image>
          </Form.Item>
        )}

        <Form.Item
          name="duration"
          label="Duration (minutes)"
          tooltip="Lesson Duration in minutes"
          rules={[
            {
              required: true,
              message: 'Please input the required field',
            },
          ]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          name="ranking"
          label="Lesson Number"
          tooltip="Smallest number will be on top: 1,2,3.."
          rules={[
            {
              required: true,
              message: 'Please input the required field',
            },
          ]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
              message: 'Please input the required field',
              whitespace: true,
            },
          ]}
        >
          <Select placeholder="Select an type">
            {Object.entries(mappingLessonType).map(([k, v]) => (
              <Option value={k}>{v}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Space>
            <Button htmlType="submit" type="primary" loading={submiting}>
              {publishButtonLabel}
            </Button>
            <Button
              onClick={() => setIsDraft(true)}
              danger
              htmlType="submit"
              loading={submiting}
            >
              {draftButtonLabel}
            </Button>
            {onDelete ? (
              <Button onClick={onDelete} danger>
                Move to Trash
              </Button>
            ) : null}
          </Space>
        </Form.Item>
      </Form>
      <Screen>
        <Container>
          <Title>{title}</Title>
          <StyledImage src={base64Photo || photo} />
          <Description description={description} />
        </Container>
      </Screen>
    </div>
  );
};

export default ItemForm;
