import {
  enhancedMakeFetchAction,
  generateEndpoint,
} from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

const FETCH_LESSON_LIST_API_ID = 'DASHBOARD/FETCH_LESSON_LIST_API_ID';

const apiCall = enhancedMakeFetchAction(
  FETCH_LESSON_LIST_API_ID,
  ({ chapter, page }) => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/stagedu-api/admin/v1/lessons`,
      params: { chapter, page },
    }),
    method: 'GET',
  })
);

export default apiCall;
