import apiCall from 'apps/education/apiCalls/surveyReport';
import { DetailPage, ListPage } from 'common/ui/AdminCRUD/page';
import { columnsGenerator } from './config';
import ItemDetail from './detail';

export const FHCListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    title="All Reports"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="data"
    {...props}
  />
);

export const FHCDetailPage = props => (
  <DetailPage
    title="FHC Report"
    apiCall={apiCall}
    listUrl={`/edu/fhc-reports`}
    ItemDetail={ItemDetail}
    resourceName="data"
    options={{ editEnabled: false }}
    {...props}
  />
);
