import { useFetchApiList, usePostApi } from '@stagapps/redux-utils';
import { Form } from 'antd';
import createApiCall from 'apps/education/apiCalls/chapter/create';
import chapterListApiCall from 'apps/education/apiCalls/chapter/list';
import PageTitle from 'apps/home/components/PageTitle';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DRAFT_STATUS, PUBLISH_STATUS } from 'utils/constants';
import { convertFormData } from 'utils/formData.js';
import ItemForm from './Form';

const ChapterCreate = () => {
  const [form] = Form.useForm();
  const params = useParams();

  const { post: submitItem, isSubmitting, error: submitItemError } = usePostApi(
    createApiCall,
    {
      resourceName: 'item',
    }
  );

  const {
    data: chapters,
    load: fetchChapters,
    isChapterLoading,
    paging,
  } = useFetchApiList(chapterListApiCall, { resourceName: 'items' });

  useEffect(() => {
    fetchChapters({ course: params.course_id });
  }, []);

  const onFinish = (values, isDraft) => {
    console.debug('Received values of form: ', values);

    const postData = async () => {
      try {
        let submitData = values;

        submitData.course = params.course_id;
        submitData.points = 0;
        submitData.status = PUBLISH_STATUS;
        if (isDraft) {
          submitData.status = DRAFT_STATUS;
        }

        if (
          values.photo &&
          values.photo.at(-1) &&
          values.photo.at(-1).originFileObj instanceof File
        ) {
          submitData.photo = values.photo[0].originFileObj;
          submitData.is_active
            ? (submitData.is_active = '1')
            : (submitData.is_active = '0');

          submitData = convertFormData(submitData);
        }

        submitItem({ payload: submitData });
      } catch (error) {
        console.error(error.message);
      }
    };

    postData();
  };

  if (isChapterLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <PageTitle title={`Create Chapter`} />
      <ItemForm
        submiting={isSubmitting}
        form={form}
        chapters={chapters}
        onFinish={onFinish}
        name="chapter_create_form"
      />
    </>
  );
};

export default ChapterCreate;
