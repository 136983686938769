import { useParams } from 'react-router-dom';

import apiCall from 'apps/investment/apiCalls/user';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import ItemForm from './Form';
import { columnsGenerator } from './config';
import ItemDetail from './detail';
import Portfolio from './portfolio';
import PortfolioItems from './portfolioItems';

export const InvestmentUserListPage = (props, { params }) => {
  return (
    <ListPage
      enableSearch={true}
      title="All User Accounts"
      apiCall={apiCall}
      params={params}
      columnGenerator={props => columnsGenerator(props)}
      resourceName="data"
      createUrl={`/investment/users/create`}
      {...props}
    />
  );
};

export const InvestmentUserEditPage = props => {
  const { id } = useParams();

  return (
    <EditPage
      title="User"
      apiCall={apiCall}
      listUrl={`/investment/users/${id}`}
      ItemForm={ItemForm}
      options={{ title: 'Edit account info' }}
      resourceName="data"
      {...props}
    />
  );
};

export const InvestmentUserDetailPage = props => {
  return (
    <DetailPage
      title="Stag Account"
      apiCall={apiCall}
      listUrl={`/investment/users`}
      ItemDetail={ItemDetail}
      resourceName="data"
      {...props}
    />
  );
};

export const InvestmentUserCreatePage = props => (
  <CreatePage
    title="User"
    apiCall={apiCall}
    listUrl={`/investment/users`}
    ItemForm={ItemForm}
    resourceName="data"
    {...props}
  />
);

export const InvestmentUserPortfolioPage = () => (
  <Portfolio apiCall={apiCall} />
);

export const InvestmentUserPortfolioItemsPage = () => (
  <PortfolioItems apiCall={apiCall} />
);
