import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import apiCall from 'apps/investment/apiCalls/userCampaign';
import { identificationStatusColors } from 'apps/investment/constants/user';
import {
  userCampaignStatusApprove,
  userCampaignStatusColor,
} from 'apps/investment/constants/userCampaign';
import { ListPage } from 'common/ui/AdminCRUD/page';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/formatDate';
import { UserCampaignAction } from './PostActions';

const columnsGenerator = params => [
  {
    title: 'User',
    dataIndex: 'user',
    render: (value, record) => (
      <Link to={`/investment/users/${record.user_id}`}>
        {record.user
          ? `${record.user?.name ?? ''} (${record.user?.email})`
          : '-'}
      </Link>
    ),
  },
  {
    title: 'Product',
    dataIndex: 'product',
    render: (value, record) =>
      record.product ? `${record.product?.code}` : '-',
  },
  {
    title: 'Monthly Contributed Amount',
    dataIndex: 'amount',
    type: 'formatNumber',
    sorter: true,
  },
  {
    title: 'KYC Status',
    dataIndex: 'user',
    align: 'center',
    render: (value, record) => {
      if (!record.user) {
        return '-';
      }

      return (
        <Tag
          color={identificationStatusColors[record.user.verification_status]}
        >
          {record.user.verification_status_label}
        </Tag>
      );
    },
  },
  {
    title: 'Fund Account Ready',
    dataIndex: 'is_fund_account_approved',
    render: (value, record) =>
      record.is_fund_account_approved ? (
        <Tooltip title={'Đã duyệt'}>
          <CheckCircleOutlined style={{ color: 'green', fontSize: '20px' }} />
        </Tooltip>
      ) : (
        <Tooltip title={'Chưa duyệt'}>
          <InfoCircleOutlined style={{ color: '#b8860b', fontSize: '20px' }} />
        </Tooltip>
      ),
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    render: value => formatDateTime(value),
    sorter: true,
  },
  {
    title: 'Status',
    align: 'center',
    render: o => {
      return (
        <Tag color={userCampaignStatusColor[o.status]}>{o.status_label}</Tag>
      );
    },
  },
  {
    title: 'Updated By',
    dataIndex: 'action_by',
    render: (value, record) => {
      if (record.action_by) {
        return (
          <>
            <Tag color="green">{record.action_by}</Tag> at{' '}
            {formatDateTime(record.action_at)}
          </>
        );
      }

      return '-';
    },
  },
  {
    title: 'Created Goal Program',
    dataIndex: 'user_program_id',
    render: (value, record) =>
      record.status == userCampaignStatusApprove ? (
        <Link to={`/investment/user-programs/${record.user_program_id}`}>
          RiskFree | Stag
        </Link>
      ) : (
        '-'
      ),
  },
  {
    title: 'Action',
    key: 'action',
    align: 'center',
    render: (text, record) => (
      <UserCampaignAction text={text} record={record} />
    ),
  },
];

export const InvestmentUserCampaignListPage = (props, { params }) => (
  <ListPage
    enableSearch={true}
    title="User Campaigns"
    apiCall={apiCall}
    params={params}
    columnGenerator={props => columnsGenerator(props)}
    resourceName="data"
    size="small"
    {...props}
  />
);
