function ItemDetail({ item }) {
  const { CreatedAt, UpdatedAt, ID, Request, Response } = item || {};
  return (
    <div className="flex flex-col gap-5">
      <div className="bg-sky-800 text-yellow-300 p-4 flex flex-col gap-2">
        <div className="">
          <span className="font-semibold">ID: </span>
          <span>{ID}</span>
        </div>
        <div>
          <span className="font-semibold">CreatedAt: </span>
          <span>{CreatedAt}</span>
        </div>
        <div>
          <span className="font-semibold">UpdatedAt: </span>
          <span>{UpdatedAt}</span>
        </div>
      </div>

      <div className="bg-sky-800 text-yellow-300 p-4">
        <p className="font-semibold text-lg">Request:</p>
        <pre>{JSON.stringify(Request, null, 2)}</pre>
      </div>

      <div className="bg-sky-800 text-yellow-300 p-4">
        <p className="font-semibold text-lg">Response:</p>
        <pre>{JSON.stringify(Response, null, 2)}</pre>
      </div>
    </div>
  );
}

export default ItemDetail;
