export const productStatusColors = {
  0: 'yellow',
  1: 'green',
};

export const productStatusLabels = {
  0: 'Drafted',
  1: 'Published',
};

export const productStatusOptions = [
  { value: 0, label: 'Drafted' },
  { value: 1, label: 'Published' },
];

export const productRiskLevelOptions = [
  { value: 1001, label: 'Low' },
  { value: 1002, label: 'Low Medium' },
  { value: 1003, label: 'Medium' },
  { value: 1004, label: 'Medium High' },
  { value: 1005, label: 'High' },
];
