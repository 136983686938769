import { enhancedMakeFetchAction } from '@stagapps/redux-utils';
import { API_BASE_URL } from 'config/env.config';

export const FETCH_QUESTION_EDIT_API_ID = 'DASHBOARD/FETCH_QUESTION_EDIT_API';

const apiCall = lesson_id =>
  enhancedMakeFetchAction(FETCH_QUESTION_EDIT_API_ID, ({ id, payload }) => ({
    endpoint: `${API_BASE_URL}/stagedu-api/admin/v1/lessons/${lesson_id}/questions/${id}`,
    method: 'PUT',
    body: payload,
    notify: true,
  }));

export default apiCall;
