import { appsConfig } from 'config/data';
import { values } from 'lodash/fp';

export default function useApps() {
  const urlItems = location.pathname.split('/').filter(o => o);
  const urlKey = urlItems[0] ?? '';
  const currentApp = appsConfig[urlKey];

  return {
    currentApp,
    appKey: currentApp ? urlKey : null,
    urlKey,
    appItems: values(appsConfig),
  };
}
