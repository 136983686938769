import { Button, Tag } from 'antd';
import {
  surveyReportStatusColors,
  surveyReportStatusLabels,
} from 'apps/education/constants/surveyReport';
import { Link } from 'react-router-dom';

export const columnsGenerator = params => [
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Name',
    dataIndex: 'user_name',
  },
  {
    title: 'Company',
    dataIndex: 'company_name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => {
      return (
        <Tag color={surveyReportStatusColors[value]}>
          {surveyReportStatusLabels[value]}
        </Tag>
      );
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={`/edu/fhc-reports/${record.id}`}>Detail</Link>
        </Button>
      </Button.Group>
    ),
  },
];
