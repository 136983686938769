import { Tag } from 'antd';
import {
  fundAccountESignStatusColors,
  fundAccountESignStatusLabels,
  fundAccountStatusColors,
} from 'apps/investment/constants/fundAccount';
import { Link } from 'react-router-dom';

import { formatDateTime } from 'utils/formatDate';

export const columnsGenerator = props => [
  {
    align: 'center',
    render: record => (
      <Link to={`/investment/fund-accounts/${record.id}`}>View</Link>
    ),
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    sorter: true,
    render: value => formatDateTime(value),
  },
  {
    title: 'Account Number',
    dataIndex: 'trading_code',
    key: 'trading_code',
    align: 'right',
  },

  {
    title: 'eSign',
    dataIndex: 'sign_contract_status',
    key: 'sign_contract_status',
    align: 'center',
    render: value => {
      if (value) {
        return (
          <Tag color={fundAccountESignStatusColors[value]}>
            {fundAccountESignStatusLabels[value]}
          </Tag>
        );
      }

      return <Tag color={'yellow'}>{value}</Tag>;
    },
  },
  {
    title: 'VSD',
    dataIndex: 'is_vsd_approved',
    key: 'is_vsd_approved',
    align: 'center',
    render: (value, record) => {
      if (value) {
        return <Tag color={'green'}>Duyệt</Tag>;
      }

      return <Tag color={'yellow'}>Chưa duyệt</Tag>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: (value, record) => {
      return (
        <Tag color={fundAccountStatusColors[value] || 'gray'}>
          {record.status}
        </Tag>
      );
    },
  },
  {
    title: 'Fund',
    dataIndex: 'fund_manager',
    align: 'center',
    render: value => value?.toUpperCase(),
  },
  {
    title: 'User email',
    render: record => (
      <Link to={`/investment/users/${record.user_id}`}>
        {record && record.name
          ? `${record.name} (${record.email})`
          : record.email}
      </Link>
    ),
  },
  {
    title: 'Approved',
    dataIndex: 'approved_at',
    key: 'approved_at',
    render: value => formatDateTime(value),
  },
];
