import { Navigate } from 'react-router-dom';

import {
  InvestmentBankDetailPage,
  InvestmentBankEditPage,
  InvestmentBankListPage,
} from 'apps/investment/components/Bank/pages';
import InvestmentCompanyEmployees from 'apps/investment/components/Companies/employees';
import {
  InvestmentCompanyCreatePage,
  InvestmentCompanyDetailPage,
  InvestmentCompanyEditPage,
  InvestmentCompanyListPage,
} from 'apps/investment/components/Companies/pages';
import InvestmentCompanyPortfolio from 'apps/investment/components/Companies/portfolio';
import InvestmentCompanyUnallocatedHistory from 'apps/investment/components/Companies/unallocatedHistory';
import {
  InvestmentProgramOrderDetailPage,
  InvestmentProgramOrderEditPage,
  InvestmentProgramOrderGeneratePage,
  InvestmentProgramOrderListPage,
  InvestmentProgramOrderRebundlePage,
} from 'apps/investment/components/EmployeeProgramOrders/pages';
import {
  ProgramDetailCreatePage,
  ProgramDetailDetailPage,
  ProgramDetailEditPage,
  ProgramDetailListPage,
  ProgramDetailPortfolioPage,
} from 'apps/investment/components/EmployeePrograms/pages';
import {
  InvestmentEmployeeCreatePage,
  InvestmentEmployeeDetailPage,
  InvestmentEmployeeEditPage,
  InvestmentEmployeeListPage,
} from 'apps/investment/components/Employees/pages';
import {
  InvestmentOrderDetailPage,
  InvestmentOrderEditPage,
  InvestmentOrderListPage,
} from 'apps/investment/components/FundOrder/pages';
import {
  IntegrationLogListPage,
  IntegrationLoglDetailPage,
} from 'apps/investment/components/IntegrationLog/config';
import {
  InvestmentPlanCreatePage,
  InvestmentPlanListPage,
} from 'apps/investment/components/InvestmentPlan/config';
import {
  InvestmentNotificationLogDetailPage,
  InvestmentNotificationLogListPage,
} from 'apps/investment/components/NotificationLog/pages';
import {
  InvestmentProductDetailPage,
  InvestmentProductEditPage,
  InvestmentProductListPage,
} from 'apps/investment/components/Product/config';
import {
  StaffCreatePage,
  StaffDetailPage,
  StaffEditPage,
  StaffListPage,
} from 'apps/investment/components/Staffs/pages';
import {
  InvestmentStockAccountDetailPage,
  InvestmentStockAccountEditPage,
  InvestmentStockAccountListPage,
} from 'apps/investment/components/StockAccounts/pages';
import {
  InvestmentStockOrderDetailPage,
  InvestmentStockOrderListPage,
} from 'apps/investment/components/StockOrder/pages';
import { InvestmentUserCampaignListPage } from 'apps/investment/components/UserCampaigns/pages';
import { InvestmentUserIdentificationDraftReKYCPage } from 'apps/investment/components/UserIdentificationDrafts/config';
import {
  InvestmentUserIdentificationImageCreatePage,
  InvestmentUserIdentificationImageListPage,
} from 'apps/investment/components/UserIdentificationImages/config';
import {
  InvestmentUserIdentificationCreatePage,
  InvestmentUserIdentificationEditPage,
} from 'apps/investment/components/UserIdentifications/config';
import {
  InvestmentUserProgramOrderCreatePage,
  InvestmentUserProgramOrderDetailPage,
  InvestmentUserProgramOrderEditPage,
  InvestmentUserProgramOrderListPage,
} from 'apps/investment/components/UserProgramOrders/pages';
import {
  UserProgramCreatePage,
  UserProgramDetailPage,
  UserProgramEditPage,
  UserProgramInvestmentPlanCreatePage,
  UserProgramInvestmentPlanListPage,
  UserProgramListPage,
  UserProgramPortfolioPage,
} from 'apps/investment/components/UserPrograms/pages';
import {
  UserSurveyHistoryCreatePage,
  UserSurveyHistoryDetailPage,
  UserSurveyHistoryEditPage,
  UserSurveyHistoryListPage,
} from 'apps/investment/components/UserSurveyHistories/pages';
import {
  InvestmentUserCreatePage,
  InvestmentUserDetailPage,
  InvestmentUserEditPage,
  InvestmentUserListPage,
  InvestmentUserPortfolioItemsPage,
  InvestmentUserPortfolioPage,
} from 'apps/investment/components/Users/pages';
import {
  InvestmentFundAccountDetailPage,
  InvestmentFundAccountEditPage,
  InvestmentFundAccountListPage,
} from '../components/FundAccount/pages';
import {
  PortfolioDetailPage,
  PortfolioListPage,
} from '../components/Portfolio';

const investmentRoutes = [
  { path: '/investment/users', element: <InvestmentUserListPage /> },
  { path: '/investment/users/create', element: <InvestmentUserCreatePage /> },
  { path: '/investment/users/:id', element: <InvestmentUserDetailPage /> },
  { path: '/investment/users/:id/edit', element: <InvestmentUserEditPage /> },
  {
    path: '/investment/users/:id/portfolio',
    element: <InvestmentUserPortfolioPage />,
  },
  {
    path: '/investment/users/:id/portfolio-items',
    element: <InvestmentUserPortfolioItemsPage />,
  },
  // {
  //   path: '/investment/portfolio-funds',
  //   element: <PortfolioFundsPage />,
  // },
  {
    path: '/investment/users/:id/identifications',
    element: <Navigate to="/investment/users" />,
  },
  {
    path: '/investment/users/:userId/identifications/:id',
    element: <Navigate to="/investment/users/:userId" />,
  },
  {
    path: '/investment/users/:userId/identifications/:accountType/create',
    element: <InvestmentUserIdentificationCreatePage />,
  },
  {
    path: '/investment/users/:userId/identifications/:accountType/:id/edit',
    element: <InvestmentUserIdentificationEditPage />,
  },
  {
    path: '/investment/users/:userId/identifications/:accountType/:id/re-kyc',
    element: <InvestmentUserIdentificationDraftReKYCPage />,
  },
  {
    path: '/investment/users/:userId/identification-images',
    element: <InvestmentUserIdentificationImageListPage />,
  },
  {
    path: '/investment/users/:userId/identification-images/create',
    element: <InvestmentUserIdentificationImageCreatePage />,
  },
  { path: '/investment/orders', element: <InvestmentOrderListPage /> },
  { path: '/investment/orders/:id', element: <InvestmentOrderDetailPage /> },
  { path: '/investment/orders/:id/edit', element: <InvestmentOrderEditPage /> },
  { path: '/investment/banks', element: <InvestmentBankListPage /> },
  { path: '/investment/banks/:id', element: <InvestmentBankDetailPage /> },
  { path: '/investment/banks/:id/edit', element: <InvestmentBankEditPage /> },
  { path: '/investment/products', element: <InvestmentProductListPage /> },
  {
    path: '/investment/products/:id',
    element: <InvestmentProductDetailPage />,
  },
  {
    path: '/investment/products/:id/edit',
    element: <InvestmentProductEditPage />,
  },
  { path: '/investment/companies', element: <InvestmentCompanyListPage /> },
  {
    path: '/investment/companies/create',
    element: <InvestmentCompanyCreatePage />,
  },
  {
    path: '/investment/companies/:id',
    element: <InvestmentCompanyDetailPage />,
  },
  {
    path: '/investment/companies/:id/edit',
    element: <InvestmentCompanyEditPage />,
  },
  {
    path: '/investment/companies/:company_id/employees',
    element: <InvestmentCompanyEmployees />,
  },
  {
    path: '/investment/companies/:company_id/portfolio',
    element: <InvestmentCompanyPortfolio />,
  },
  {
    path: '/investment/companies/:company_id/unallocated-history',
    element: <InvestmentCompanyUnallocatedHistory />,
  },
  { path: '/investment/employees', element: <InvestmentEmployeeListPage /> },
  {
    path: '/investment/employees/create',
    element: <InvestmentEmployeeCreatePage />,
  },
  {
    path: '/investment/employees/:id',
    element: <InvestmentEmployeeDetailPage />,
  },
  {
    path: '/investment/employees/:id/edit',
    element: <InvestmentEmployeeEditPage />,
  },
  {
    path: '/investment/employees/:id/info',
    element: <InvestmentEmployeeDetailPage />,
  },

  { path: '/investment/employee-programs', element: <ProgramDetailListPage /> },
  {
    path: '/investment/employee-programs/create',
    element: <ProgramDetailCreatePage />,
  },
  {
    path: '/investment/employee-programs/:id',
    element: <ProgramDetailDetailPage />,
  },
  {
    path: '/investment/employee-programs/:id/portfolio',
    element: <ProgramDetailPortfolioPage />,
  },
  {
    path: '/investment/employee-programs/:id/edit',
    element: <ProgramDetailEditPage />,
  },
  {
    path: '/investment/employee-programs/:id/investment-plans',
    element: <InvestmentPlanListPage />,
  },
  {
    path: '/investment/employee-programs/:id/investment-plans/create',
    element: <InvestmentPlanCreatePage />,
  },
  {
    path: '/investment/employee-programs/:id/edit',
    element: <ProgramDetailDetailPage />,
  },

  { path: '/investment/user-programs', element: <UserProgramListPage /> },
  {
    path: '/investment/user-programs/:id',
    element: <UserProgramDetailPage />,
  },
  {
    path: '/investment/user-programs/:id/portfolio',
    element: <UserProgramPortfolioPage />,
  },
  {
    path: '/investment/user-programs/:id/investment-plans',
    element: <UserProgramInvestmentPlanListPage />,
  },
  {
    path: '/investment/user-programs/:id/investment-plans/create',
    element: <UserProgramInvestmentPlanCreatePage />,
  },
  {
    path: '/investment/user-programs/:id/edit',
    element: <UserProgramEditPage />,
  },
  {
    path: '/investment/user-programs/create',
    element: <UserProgramCreatePage />,
  },
  {
    path: '/investment/user-program-orders',
    element: <InvestmentUserProgramOrderListPage />,
  },
  {
    path: '/investment/user-program-orders/create',
    element: <InvestmentUserProgramOrderCreatePage />,
  },
  {
    path: '/investment/user-program-orders/:id/edit',
    element: <InvestmentUserProgramOrderEditPage />,
  },
  {
    path: '/investment/user-program-orders/:id',
    element: <InvestmentUserProgramOrderDetailPage />,
  },

  {
    path: '/investment/program-orders',
    element: <InvestmentProgramOrderListPage />,
  },
  {
    path: '/investment/program-orders/generate',
    element: <InvestmentProgramOrderGeneratePage />,
  },
  {
    path: '/investment/program-orders/rebundle',
    element: <InvestmentProgramOrderRebundlePage />,
  },
  {
    path: '/investment/program-orders/:id/edit',
    element: <InvestmentProgramOrderEditPage />,
  },
  {
    path: '/investment/program-orders/:id',
    element: <InvestmentProgramOrderDetailPage />,
  },
  { path: '/investment/integration-logs', element: <IntegrationLogListPage /> },
  {
    path: '/investment/integration-logs/:id',
    element: <IntegrationLoglDetailPage />,
  },
  { path: '/investment/staffs', element: <StaffListPage /> },
  { path: '/investment/staffs/create', element: <StaffCreatePage /> },
  { path: '/investment/staffs/:id/edit', element: <StaffEditPage /> },
  { path: '/investment/staffs/:id', element: <StaffDetailPage /> },
  {
    path: '/investment/notification-logs',
    element: <InvestmentNotificationLogListPage />,
  },
  {
    path: '/investment/notification-logs/:id',
    element: <InvestmentNotificationLogDetailPage />,
  },
  {
    path: '/investment/user-survey-histories',
    element: <UserSurveyHistoryListPage />,
  },
  {
    path: '/investment/user-survey-histories/create',
    element: <UserSurveyHistoryCreatePage />,
  },
  {
    path: '/investment/user-survey-histories/:id',
    element: <UserSurveyHistoryDetailPage />,
  },
  {
    path: '/investment/user-survey-histories/:id/edit',
    element: <UserSurveyHistoryEditPage />,
  },
  {
    path: '/investment/fund-accounts',
    element: <InvestmentFundAccountListPage />,
  },
  {
    path: '/investment/fund-accounts/:id',
    element: <InvestmentFundAccountDetailPage />,
  },
  {
    path: '/investment/fund-accounts/:id/edit',
    element: <InvestmentFundAccountEditPage />,
  },
  {
    path: '/investment/stock-accounts',
    element: <InvestmentStockAccountListPage />,
  },
  {
    path: '/investment/stock-accounts/:id/edit',
    element: <InvestmentStockAccountEditPage />,
  },
  {
    path: '/investment/stock-accounts/:id',
    element: <InvestmentStockAccountDetailPage />,
  },
  {
    path: '/investment/user-campaigns',
    element: <InvestmentUserCampaignListPage />,
  },
  {
    path: '/investment/stock-orders',
    element: <InvestmentStockOrderListPage />,
  },
  {
    path: '/investment/stock-orders/:id',
    element: <InvestmentStockOrderDetailPage />,
  },
  {
    path: '/investment/portfolios',
    element: <PortfolioListPage />,
  },
  {
    path: '/investment/portfolios/:id',
    element: <PortfolioDetailPage />,
  },
];

export default investmentRoutes;
