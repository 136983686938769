export const fundAccountStatusColors = {
  new: 'cyan',
  waiting_customer: 'orange',
  pending: 'yellow',
  approved: 'green',
};

export const fundAccountESignStatusColors = {
  waiting_customer: 'orange',
  pending: 'yellow',
  finished: 'green',
};

export const fundAccountESignStatusLabels = {
  waiting_customer: 'Waiting for customer',
  pending: 'Pending',
  finished: 'Completed',
};
