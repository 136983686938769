import ChallengeGroups from 'apps/education/components/ChallengeGroups';
import ChallengeGroupCreate from 'apps/education/components/ChallengeGroups/create';
import ChallengeGroupEdit from 'apps/education/components/ChallengeGroups/edit';
import Challenges from 'apps/education/components/Challenges';
import ChallengeEdit from 'apps/education/components/Challenges/edit';
import Chapters from 'apps/education/components/Chapters';
import ChapterCreate from 'apps/education/components/Chapters/create';
import ChapterEdit from 'apps/education/components/Chapters/edit';
import Courses from 'apps/education/components/Courses';
import CourseCreate from 'apps/education/components/Courses/create';
import CourseEdit from 'apps/education/components/Courses/edit';
import LessonPolls from 'apps/education/components/LessonPolls';
import LessonPollCreate from 'apps/education/components/LessonPolls/create';
import LessonPollEdit from 'apps/education/components/LessonPolls/edit';
import LessonVideos from 'apps/education/components/LessonVideos';
import LessonVideoCreate from 'apps/education/components/LessonVideos/create';
import LessonVideoEdit from 'apps/education/components/LessonVideos/edit';
import Lessons from 'apps/education/components/Lessons';
import LessonCreate from 'apps/education/components/Lessons/create';
import LessonEdit from 'apps/education/components/Lessons/edit';
import Notifications from 'apps/education/components/Notifications';
import NotificationCreate from 'apps/education/components/Notifications/create';
import NotificationEdit from 'apps/education/components/Notifications/edit';
import Polls from 'apps/education/components/Polls';
import PollEdit from 'apps/education/components/Polls/edit';
import Questions from 'apps/education/components/Questions';
import QuestionCreate from 'apps/education/components/Questions/create';
import QuestionEdit from 'apps/education/components/Questions/edit';
import Topics from 'apps/education/components/Topics';
import TopicCreate from 'apps/education/components/Topics/create';
import TopicEdit from 'apps/education/components/Topics/edit';
import { UserDeviceListPage } from 'apps/education/components/UserDevices/config';

// CMS

import {
  AdvertisementCreatePage,
  AdvertisementEditPage,
  AdvertisementListPage,
} from 'apps/education/components/Advertisement/pages';
import {
  ArticleCreatePage,
  ArticleEditPage,
  ArticleListPage,
} from 'apps/education/components/Articles/config';
import ChallengeCreate from 'apps/education/components/Challenges/create';
import {
  CommunityCreatePage,
  CommunityEditPage,
  CommunityListPage,
} from 'apps/education/components/Communities/config';
import { GameOrdersListPage } from 'apps/education/components/GameOrders/config';
import { GamePortfolioItemsListPage } from 'apps/education/components/GamePortfolioItems/config';
import {
  GameRoomCreatePage,
  GameRoomEditPage,
  GameRoomListPage,
} from 'apps/education/components/GameRooms/pages';
import { GameSettingEditPage } from 'apps/education/components/GameSettings/config';
import {
  GameParticipantImport,
  GameUserCreatePage,
  GameUserEditPage,
  GameUserListPage,
} from 'apps/education/components/GameUsers/pages';
import PollCreate from 'apps/education/components/Polls/create';
import { PortfolioStocksListPage } from 'apps/education/components/PortfolioStocks';
import { PortfoliosListPage } from 'apps/education/components/Portfolios';
import {
  StockDividendCreatePage,
  StockDividendEditPage,
  StockDividendListPage,
} from 'apps/education/components/StockDividend/config';
import {
  UserCommunityCreatePage,
  UserCommunityEditPage,
  UserCommunityListPage,
} from 'apps/education/components/UserCommunities/config';
import { FHCDetailPage, FHCListPage } from '../components/FHC/pages';
const educationRoutes = [
  { path: '/edu/courses', element: <Courses /> },
  { path: '/edu/courses/create', element: <CourseCreate /> },
  { path: '/edu/courses/:id', element: <CourseEdit /> },
  { path: '/edu/courses/:id/edit', element: <CourseEdit /> },
  { path: '/edu/courses/:course_id/chapters', element: <Chapters /> },
  {
    path: '/edu/courses/:course_id/chapters/create',
    element: <ChapterCreate />,
  },
  { path: '/edu/courses/:course_id/chapters/:id', element: <ChapterEdit /> },
  {
    path: '/edu/courses/:course_id/chapters/:id/edit',
    element: <ChapterEdit />,
  },
  {
    path: '/edu/courses/:course_id/chapters/:chapter_id/lessons',
    element: <Lessons />,
  },
  {
    path: '/edu/courses/:course_id/chapters/:chapter_id/lessons/create',
    element: <LessonCreate />,
  },
  {
    path: '/edu/courses/:course_id/chapters/:chapter_id/lessons/:id/edit',
    element: <LessonEdit />,
  },
  {
    path:
      '/edu/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/questions',
    element: <Questions />,
  },
  {
    path:
      '/edu/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/questions/create',
    element: <QuestionCreate />,
  },
  {
    path:
      '/edu/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/questions/:id',
    element: <QuestionEdit />,
  },
  {
    path:
      '/edu/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/questions/:id/edit',
    element: <QuestionEdit />,
  },
  {
    path:
      '/edu/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/polls',
    element: <LessonPolls />,
  },
  {
    path:
      '/edu/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/polls/create',
    element: <LessonPollCreate />,
  },
  {
    path:
      '/edu/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/polls/:id',
    element: <LessonPollEdit />,
  },
  {
    path:
      '/edu/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/polls/:id/edit',
    element: <LessonPollEdit />,
  },
  {
    path:
      '/edu/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/videos',
    element: <LessonVideos />,
  },
  {
    path:
      '/edu/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/videos/create',
    element: <LessonVideoCreate />,
  },
  {
    path:
      '/edu/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/videos/:id',
    element: <LessonVideoEdit />,
  },
  {
    path:
      '/edu/courses/:course_id/chapters/:chapter_id/lessons/:lesson_id/videos/:id/edit',
    element: <LessonVideoEdit />,
  },
  { path: '/edu/topics', element: <Topics /> },
  { path: '/edu/topics/create', element: <TopicCreate /> },
  { path: '/edu/topics/:id', element: <TopicEdit /> },
  { path: '/edu/topics/:id/edit', element: <TopicEdit /> },
  { path: '/edu/challenges', element: <Challenges /> },
  { path: '/edu/challenges/create', element: <ChallengeCreate /> },
  { path: '/edu/challenges/:id', element: <ChallengeEdit /> },
  { path: '/edu/challenges/:id/edit', element: <ChallengeEdit /> },
  { path: '/edu/challenge-groups', element: <ChallengeGroups /> },
  { path: '/edu/challenge-groups/create', element: <ChallengeGroupCreate /> },
  { path: '/edu/challenge-groups/:id', element: <ChallengeGroupEdit /> },
  { path: '/edu/challenge-groups/:id/edit', element: <ChallengeGroupEdit /> },
  { path: '/edu/notifications', element: <Notifications /> },
  { path: '/edu/notifications/create', element: <NotificationCreate /> },
  { path: '/edu/notifications/:id', element: <NotificationEdit /> },
  { path: '/edu/notifications/:id/edit', element: <NotificationEdit /> },
  { path: '/edu/polls', element: <Polls /> },
  { path: '/edu/polls/create', element: <PollCreate /> },
  { path: '/edu/polls/:id', element: <PollEdit /> },
  { path: '/edu/polls/:id/edit', element: <PollEdit /> },
  { path: '/edu/user-devices', element: <UserDeviceListPage /> },
  { path: '/edu/communities', element: <CommunityListPage /> },
  { path: '/edu/communities/create', element: <CommunityCreatePage /> },
  { path: '/edu/communities/:id', element: <CommunityEditPage /> },
  { path: '/edu/communities/:id/edit', element: <CommunityEditPage /> },
  {
    path: '/edu/communities/:community_id/users',
    element: <UserCommunityListPage />,
  },
  {
    path: '/edu/communities/:community_id/users/create',
    element: <UserCommunityCreatePage />,
  },
  {
    path: '/edu/communities/:community_id/users/:id/edit',
    element: <UserCommunityEditPage />,
  },
  { path: '/edu/stock-dividends', element: <StockDividendListPage /> },
  { path: '/edu/stock-dividends/create', element: <StockDividendCreatePage /> },
  { path: '/edu/stock-dividends/:id', element: <StockDividendEditPage /> },
  { path: '/edu/stock-dividends/:id/edit', element: <StockDividendEditPage /> },
  { path: '/edu/banners', element: <ArticleListPage /> },
  { path: '/edu/banners/create', element: <ArticleCreatePage /> },
  { path: '/edu/banners/:id', element: <ArticleEditPage /> },
  { path: '/edu/banners/:id/edit', element: <ArticleEditPage /> },
  { path: '/edu/game-rooms', element: <GameRoomListPage /> },
  { path: '/edu/game-rooms/create', element: <GameRoomCreatePage /> },
  { path: '/edu/game-rooms/:id/edit', element: <GameRoomEditPage /> },
  { path: '/edu/game-rooms/:id/settings', element: <GameSettingEditPage /> },
  {
    path: '/edu/game-rooms/:id/portfolio-items',
    element: <GamePortfolioItemsListPage />,
  },
  { path: '/edu/game-rooms/:id/orders', element: <GameOrdersListPage /> },
  { path: '/edu/game-rooms/:id/users', element: <GameUserListPage /> },
  { path: '/edu/game-rooms/:id/users/:user_id', element: <GameUserEditPage /> },
  {
    path: '/edu/game-rooms/:id/users/:user_id/edit',
    element: <GameUserEditPage />,
  },
  { path: '/edu/game-rooms/:id/users/create', element: <GameUserCreatePage /> },
  {
    path: '/edu/game-rooms/:id/users/import',
    element: <GameParticipantImport />,
  },
  { path: '/edu/game-rooms/:id', element: <GameRoomEditPage /> },
  { path: '/edu/stock-dividends', element: <StockDividendListPage /> },
  { path: '/edu/stock-dividends/create', element: <StockDividendCreatePage /> },
  { path: '/edu/stock-dividends/:id', element: <StockDividendEditPage /> },
  { path: '/edu/stock-dividends/:id/edit', element: <StockDividendEditPage /> },
  { path: '/edu/portfolios', element: <PortfoliosListPage /> },
  { path: '/edu/portfolio-stocks', element: <PortfolioStocksListPage /> },

  { path: '/edu/advertisement', element: <AdvertisementListPage /> },
  { path: '/edu/advertisement/create', element: <AdvertisementCreatePage /> },
  { path: '/edu/advertisement/:id/edit', element: <AdvertisementEditPage /> },
  { path: '/edu/fhc-reports', element: <FHCListPage /> },
  { path: '/edu/fhc-reports/:id', element: <FHCDetailPage /> },
];

export default educationRoutes;
