export const surveyReportStatusLabels = {
  1001: 'New',
  1002: 'Processing',
  1003: 'Done',
};

export const surveyReportStatusColors = {
  1001: '#52c41a',
  1002: '#FFD700',
  1003: '#a9a9a9',
};
