import { FileImageOutlined, PieChartOutlined } from '@ant-design/icons';
import { Button, Space, Tag } from 'antd';
import { Link } from 'react-router-dom';

import {
  identificationStatusColors,
  securityAccountStatusColors,
} from 'apps/investment/constants/user';
import { formatDateTime } from 'utils/formatDate';

import UIIconTooltip from 'common/ui/UIIconTooltip';
import VerificationMark from 'common/ui/VerificationMark';

export const columnsGenerator = props => [
  {
    align: 'center',
    render: record => <Link to={`/investment/users/${record.id}`}>View</Link>,
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    sorter: true,
    render: value => formatDateTime(value),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (value, record) => (
      <Space direction="vertical">
        {value ? <div>{value}</div> : null}
        <VerificationMark
          value={record.email}
          isVerified={record.email_verified}
        />
      </Space>
    ),
  },
  {
    title: 'Phone',
    dataIndex: 'email',
    render: (value, record) => (
      <Space direction="vertical">
        <VerificationMark
          value={record.phone}
          isVerified={record.phone_verified}
        />
      </Space>
    ),
  },
  {
    title: 'KYC',
    dataIndex: 'identification',
    align: 'center',
    render: o =>
      o && (
        <Tag color={identificationStatusColors[o.verification_status]}>
          {o.verification_status_label}
        </Tag>
      ),
  },
  {
    title: 'Account Type',
    dataIndex: 'account_type',
    align: 'center',
    sorter: true,
    render: value =>
      value == 'business' ? (
        <Tag color={'blue'}>{value}</Tag>
      ) : (
        <Tag color={'green'}>{value}</Tag>
      ),
  },
  {
    title: 'Trading Accounts',
    align: 'center',
    render: obj => {
      if (!obj) return '-';

      return (
        <Space direction="vertical">
          {obj.fund_account && (
            <Tag
              color={obj.fund_account.status != 'approved' ? 'orange' : 'blue'}
            >
              {obj.fund_account.fund_manager_name}{' '}
              {obj.fund_account.trading_code}{' '}
              {obj.fund_account.enabled_b2b ? <>(FutureFlex)</> : ''}
              {obj.fund_account.status != 'approved' ? (
                <>({obj.fund_account.status})</>
              ) : (
                ''
              )}
            </Tag>
          )}
          {obj.stock_account && (
            <Tag color={securityAccountStatusColors[obj.stock_account.status]}>
              NHSV {obj.stock_account.account_number}{' '}
              {obj.stock_account.status !== 1007 ? (
                <>({obj.stock_account.status_label})</>
              ) : (
                ''
              )}
            </Tag>
          )}
        </Space>
      );
    },
  },

  {
    title: 'Action',
    key: 'action',
    align: 'center',
    render: (text, record) => (
      <>
        {record && record.ekyc_verified == true && (
          <Link to={`/investment/portfolios?search=${record.email}`}>
            View Portfolio
          </Link>
        )}{' '}
        {/* {record && record.ekyc_verified == false && (
          <Link to={`/investment/users/${record.id}/identification-images`}>
            View eKYC Image
          </Link>
        )} */}
      </>
    ),
  },
];

export const columnsFundAccount = ({
  userID,
  createVcamB2BAccount,
  isCreatingVcamB2BAccount,
  resyncStatus,
  isResynchronizing,
}) => [
  {
    title: '',
    dataIndex: 'id',
    align: 'center',
    render: id => <Link to={`/investment/fund-accounts/${id}`}>View</Link>,
  },
  {
    title: 'Trading Code',
    dataIndex: 'trading_code',
    key: 'trading_code',
  },
  {
    title: 'Approved At',
    dataIndex: 'approved_at',
    key: 'approved_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: value => (
      <Tag color={identificationStatusColors[value]}>{value}</Tag>
    ),
  },
  {
    title: 'eSign',
    dataIndex: 'sign_contract_status',
    key: 'sign_contract_status',
    render: (value, record) => {
      if (value) {
        return <Tag color={'green'}>Đã ký</Tag>;
      }

      return <Tag color={'yellow'}>Chưa ký</Tag>;
    },
  },
  {
    title: 'VSD',
    dataIndex: 'is_vsd_approved',
    key: 'is_vsd_approved',
    render: (value, record) => {
      if (value) {
        return <Tag color={'green'}>Duyệt</Tag>;
      }

      return <Tag color={'yellow'}>Chưa duyệt</Tag>;
    },
  },
  {
    title: 'Fund Name',
    dataIndex: 'fund_manager_code',
    render: value => value?.toUpperCase(),
  },
  {
    title: 'Fund Account Id',
    dataIndex: 'account_id',
    key: 'account_id',
  },
  {
    title: 'Enable B2B',
    key: 'enable_b2b_action',
    render: (text, record) => {
      if (record.enabled_b2b) {
        return <Tag color="green">Đã kích hoạt</Tag>;
      }

      if (record.status != 'approved') {
        return <Tag color="red">VCAM B2C Account cần approve</Tag>;
      }

      return (
        <Button
          disabled={isCreatingVcamB2BAccount}
          onClick={() =>
            confirm('Confirm to enable VCAM B2B account?') &&
            createVcamB2BAccount({ id: userID })
          }
          type="primary"
          style={{
            margin: 10,
            backgroundColor: 'green',
            borderColor: 'green',
          }}
        >
          Enable VCAM B2B Account
        </Button>
      );
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => {
      if (record && record.status != 'approved') {
        return resyncStatus ? (
          <Button
            disabled={isResynchronizing}
            onClick={() =>
              confirm('Confirm to resync account status from VCAM?') &&
              resyncStatus({ id: userID })
            }
            type="primary"
            style={{
              margin: 10,
              backgroundColor: 'green',
              borderColor: 'green',
            }}
          >
            Resync Fund Status
          </Button>
        ) : (
          '-'
        );
      }

      return '-';
    },
  },
];

export const columnsStockAccount = ({
  userID,
  createStockAccount,
  isCreatingStockAccount,
}) => [
  {
    title: '',
    dataIndex: 'id',
    align: 'center',
    render: id => <Link to={`/investment/stock-accounts/${id}`}>View</Link>,
  },
  {
    title: 'Account Number',
    dataIndex: 'account_number',
    key: 'account_number',
  },
  {
    title: 'NH Approved At',
    dataIndex: 'approved_at',
    key: 'approved_at',
    render: value => (value ? formatDateTime(value) : '-'),
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: value => formatDateTime(value),
  },
  {
    title: 'Contract Number',
    dataIndex: 'contract_number',
    key: 'contract_number',
  },
  {
    title: 'eSign',
    dataIndex: 'is_sign_contract',
    key: 'is_sign_contract',
    render: (value, record) => {
      if (value) {
        return <Tag color={'green'}>Đã ký</Tag>;
      }

      return <Tag color={'yellow'}>Chưa ký</Tag>;
    },
  },
  {
    title: 'VSD',
    dataIndex: 'is_vsd_approved',
    key: 'is_vsd_approved',
    render: (value, record) => {
      if (value) {
        return <Tag color={'green'}>Đã ký</Tag>;
      }

      return <Tag color={'yellow'}>Chưa ký</Tag>;
    },
  },
  {
    title: 'Security Manager',
    dataIndex: 'security_manager',
    key: 'security_manager',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (value, record) => {
      return (
        <Tag color={securityAccountStatusColors[record.status]}>
          {record.status_label}
        </Tag>
      );
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => {
      if (record && record.account_number != '') {
        return '-';
      }

      return (
        <Button
          disabled={isCreatingStockAccount}
          onClick={() =>
            confirm('Confirm to submit NHSV to create stock account?') &&
            createStockAccount({ id: userID })
          }
          type="primary"
          style={{
            margin: 10,
            backgroundColor: 'green',
            borderColor: 'green',
          }}
        >
          Send to NHSV
        </Button>
      );
    },
  },
];
