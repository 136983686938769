import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

const BreadCrumb = () => {
  const location = useLocation();
  const { pathname } = location;
  const pathnames = pathname.split('/').filter(item => item);
  const capatilize = s => s.charAt(0).toUpperCase() + s.slice(1);

  return (
    <Breadcrumb>
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;
        const key = name + index;
        return isLast ? (
          <Breadcrumb.Item key={key}>{capatilize(name)}</Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={key}>
            <Link to={`${routeTo}`}>{capatilize(name)}</Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default BreadCrumb;
