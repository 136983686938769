import {
  useFetchApiGet,
  useFetchApiList,
  usePostApi,
} from '@stagapps/redux-utils';
import { Form } from 'antd';
import deleteApiCall from 'apps/education/apiCalls/chapter/delete';
import getApiCall from 'apps/education/apiCalls/chapter/detail';
import editApiCall from 'apps/education/apiCalls/chapter/edit';
import chapterListApiCall from 'apps/education/apiCalls/chapter/list';
import PageTitle from 'apps/home/components/PageTitle';
import ErrorMessage from 'common/ui/ErrorMessage';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { convertFormData } from 'utils/formData.js';
import ItemForm from './Form';

const ChapterEdit = () => {
  const [form] = Form.useForm();
  const params = useParams();

  const {
    data: item,
    load: fetchItem,
    isLoading,
    error: fetchItemError,
  } = useFetchApiGet(getApiCall, { resourceName: 'item' });

  const { post: submitItem, isSubmitting, error: submitItemError } = usePostApi(
    editApiCall,
    {
      resourceName: 'item',
    }
  );

  const {
    post: deleteItem,
    isSubmitting: isDeleting,
    error: deleteError,
  } = usePostApi(deleteApiCall, {
    resourceName: 'item',
  });

  const {
    data: chapters,
    load: fetchChapters,
    isChapterLoading,
    paging,
  } = useFetchApiList(chapterListApiCall, { resourceName: 'items' });

  useEffect(() => {
    fetchItem({ id: params.id });
    fetchChapters({ course: params.course_id });
  }, []);

  const onFinish = (values, isRemoved) => {
    console.debug('Received values of form: ', values);

    try {
      let submitData = values;
      submitData.course = params.course_id;
      submitData.points = 0;

      if (
        values.photo &&
        values.photo.at(-1) &&
        values.photo.at(-1).originFileObj instanceof File
      ) {
        submitData.photo = values.photo[0].originFileObj;
        submitData = convertFormData(submitData);
      } else {
        delete submitData['photo'];
        if (isRemoved) {
          submitData.photo = null;
        }
      }

      submitItem({ id: params.id, payload: submitData });
    } catch (error) {
      console.error(error.message);
    }
  };

  if (isLoading || isChapterLoading) {
    return <LoadingSpinner />;
  }

  if (fetchItemError) {
    return <ErrorMessage {...fetchItemError} />;
  }

  let newChapters = chapters.filter(c => c.id != params.id);

  return (
    <>
      <PageTitle title={`Edit Chapter`} />
      <ItemForm
        submiting={isSubmitting}
        form={form}
        chapters={newChapters}
        onFinish={onFinish}
        initialValues={item}
        name="chapter_edit_form"
        visibility="visible"
        onDelete={() => {
          if (confirm('Are you sure?')) {
            deleteItem({ id: params.id });
          }
        }}
      />
    </>
  );
};

export default ChapterEdit;
